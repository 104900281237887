import Head from 'next/head';
import { usePage } from '$templates/pages';
import React, { useMemo } from 'react';

type Props = {
    pageBaseUrl?: string;
    pageUrl?: string;
};

export const MetaFields = ({ pageBaseUrl, pageUrl }: Props) => {
    const {
        meta,
        hrefLangs,
        // globalSeoSettings,
        pageReferenceImage,
        pageReferenceTitle,
        pageReferenceDescription,
        breadcrumb,
        culture,
        market,
        type,
    } = usePage();
    const { seoTitle, seoDescription, canonicalUrl, 
        // noIndex, noFollow, 
        viewport } = meta || {};

    const pageTitle =
        breadcrumb?.items && breadcrumb.items.length > 0
            ? breadcrumb.items[breadcrumb.items.length - 1].title
            : '';

    const locale = market ? `${culture}/${market.toLowerCase()}` : `${culture}`;

    const getTitle = useMemo(() => {
        if (seoTitle) return seoTitle;
        if (pageReferenceTitle) return `Kompan | ${pageReferenceTitle}`;
        if (pageTitle) return `Kompan | ${pageTitle}`;
        return null;
    }, [seoTitle, pageReferenceTitle, pageTitle]);

    const getDescription = useMemo(() => {
        if (seoDescription) return seoDescription;
        if (pageReferenceDescription) return `Kompan | ${pageReferenceDescription}`;
        return null;
    }, [seoDescription, pageReferenceDescription]);

    // This fixes an issue where P70 <Head> tag would not mount again after re-render.
    const shouldRenderSeoFields = useMemo(() => {
        if (type === 'p70ProductDetailsPageTemplate') return false;
        return true;
    }, [type]);

    return (
        <Head>
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content={viewport ? viewport : 'initial-scale=1.0, width=device-width'}
            />

            {getTitle && shouldRenderSeoFields && (
                <>
                    <meta key="og:title" property="og:title" content={getTitle} />
                    <title key="title">{getTitle}</title>
                </>
            )}
            {getDescription && shouldRenderSeoFields && (
                <>
                    <meta key="og:description" property="og:description" content={getDescription} />
                    <meta key="description" name="description" content={getDescription} />
                </>
            )}

            <meta
                key="robots"
                name="robots"
                content={`noindex, nofollow`}
            />
            {canonicalUrl ? (
                <link rel="canonical" href={pageBaseUrl + canonicalUrl} />
            ) : pageUrl ? (
                <link
                    rel="canonical"
                    href={`${pageBaseUrl}${
                        pageUrl.toLowerCase().includes(locale)
                            ? pageUrl.split('?')[0]
                            : '/' + locale + pageUrl.split('?')[0]
                    }`}
                />
            ) : null}
            {hrefLangs?.map(({ url, xDefault }) => {
                const urlParts = url.split('/').filter((x) => x);
                const marketKey = `${urlParts?.[0]}-${urlParts?.[1]?.toUpperCase()}`;

                // prevent render of unpublished markets
                if (marketKey !== 'ar-SA' && url.length > 1)
                    return (
                        <link
                            rel="alternate"
                            key={`hreflang-${marketKey}`}
                            hrefLang={xDefault ? 'x-default' : `${marketKey}`}
                            href={`${pageBaseUrl}${url}`}
                        />
                    );
            })}

            {pageReferenceImage?.src && (
                <meta key="og:image" property="og:image" content={pageReferenceImage?.src} />
            )}
        </Head>
    );
};
